import { ApiAction } from '../api-actions';
import { ApiModule } from '../api-modules/api-modules';
import { linkModuleAndAction } from '../link-module-and-action';

export const ProjectsPermissions = {
	ADD_QUICK_FILL_PERMISSION: linkModuleAndAction(ApiModule.QUICK_FILLING, ApiAction.ADD),
	ARCHIVE_PROJECT_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.ARCHIVE),
	CREATE_PROJECT_FOLDERS_PERMISSION: linkModuleAndAction(ApiModule.PROJECT_FOLDERS, ApiAction.CREATE),
	CREATE_PROJECT_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.CREATE),
	DELETE_PROJECT_FOLDERS_PERMISSION: linkModuleAndAction(ApiModule.PROJECT_FOLDERS, ApiAction.DELETE),
	DELETE_PROJECT_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.DELETE),
	DOWNLOAD_PROJECT_FOLDERS_PERMISSION: linkModuleAndAction(ApiModule.PROJECT_FOLDERS, ApiAction.DOWNLOAD),
	EMPTY_QUICK_FILLING_PERMISSION: linkModuleAndAction(ApiModule.QUICK_FILLING, ApiAction.EMPTY),
	EXPORT_PROJECT_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.EXPORT),
	HIDE_PROJECT_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.HIDE),
	MOVE_PROJECT_FOLDERS_PERMISSION: linkModuleAndAction(ApiModule.PROJECT_FOLDERS, ApiAction.MOVE),
	PRINT_PROJECT_FILE_PERMISSION: linkModuleAndAction(ApiModule.PRINT_JOBS, ApiAction.ADD),
	READ_PROJECT_FOLDERS_PERMISSION: linkModuleAndAction(ApiModule.PROJECT_FOLDERS, ApiAction.READ),
	READ_PROJECTS_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.READ),
	READ_QUICK_FILLING_PERMISSION: linkModuleAndAction(ApiModule.QUICK_FILLING, ApiAction.READ),
	REMOVE_QUICK_FILLING_PERMISSION: linkModuleAndAction(ApiModule.QUICK_FILLING, ApiAction.REMOVE),
	SHOW_PROJECT_ARCHIVE_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.PROJECT_ARCHIVE),
	SHOW_PROJECT_DATA_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.PROJECT_DATA),
	SHOW_PROJECT_LOGS_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.PROJECT_LOGS),
	SHOW_PROJECT_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.SHOW),
	SHOW_PROJECT_PRINT_JOBS_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.PROJECT_PRINT_JOBS),
	SHOW_PROJECT_USERS_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.PROJECT_USERS),
	UPDATE_PROJECT_FOLDERS_PERMISSION: linkModuleAndAction(ApiModule.PROJECT_FOLDERS, ApiAction.UPDATE),
	UPDATE_PROJECT_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.UPDATE),
	UPLOAD_PROJECT_FOLDER_FILES_PERMISSION: linkModuleAndAction(ApiModule.PROJECT_FOLDER_FILES, ApiAction.UPLOAD),
};
